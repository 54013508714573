import React from "react"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Paragraph } from "../components/text/Paragraph"
import NewsletterIllustration from "../images/newsletter_illustration.svg"

export interface NewsletterBestaetigungProps {}

const NewsletterBestaetigung: React.SFC<NewsletterBestaetigungProps> = () => {
  return (
    <Layout>
      <Section>
        <div className="text-center my-10 lg:my-24">
          <div className="flex justify-center mb-20">
            <img
              src={NewsletterIllustration}
              className="w-4/12 max-h-full max-w-full mx-auto"
              alt="Newsletter Bestätigung Illustration"
            />
          </div>
          <Paragraph>
            Wir haben Ihre Aufnahmeanfrage erhalten und senden Ihnen eine
            Bestätigungsmail zu.
          </Paragraph>
          <Paragraph>
            Sobald Sie diese bestätigen, dürfen wir Sie auf dem Laufenden
            halten.
          </Paragraph>
        </div>
      </Section>
    </Layout>
  )
}

export default NewsletterBestaetigung
